import React, { Component } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../components/Heros"
import { DualSplit } from "../../components/Grid"
import HubspotForm from "react-hubspot-form"
import Button from "../../components/Button"

import cx from "classnames"
import styles from "./styles.module.scss"
import hubspotStyles from "./hubspot.css"

class Contact extends Component {
  render() {
    return (
      <Layout>
        <Seo title="Sorted | Contact" keywords={[`Contact`, `Get in touch`]} />
        <PrimaryHero theme="pink">
          <HeroCopyBlock title={["Want to connect", "with us?"]}>
            <p>Great. we'd love for you to get in touch.</p>
          </HeroCopyBlock>
        </PrimaryHero>

        <div className={styles.contactSpacing}>
          <div className="container">
            <div className={styles.contactGrid}>
              <div className={cx(styles.contactItem, styles.contactItemGrey)}>
                <div className={styles.contactItemIcon}></div>
                <div>
                  <h3>Our Social</h3>
                  <p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/SortedOfficial/">Facebook</a></p>
                  <p><a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/sorted_official/">Instagram</a></p>
                  <p><a rel="noopener noreferrer" target="_blank" href="https://twitter.com/sortedofficial?lang=en">Twitter</a></p>
                  <p><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/sorted-official">LinkedIn</a></p>
                </div>
              </div>
              <div className={cx(styles.contactItem, styles.contactItemPurple)}>
                <div className={styles.contactItemIcon}></div>
                <div>
                  <h3>Our Phone</h3>
                  <p>For general enquiries:<br /><a href="tel:03300555284">03300 555 284</a></p>
                  <p>For sales and support:<br /><a href="tel:03300555284">03300 555 284</a></p>
                </div>
              </div>
              <div className={cx(styles.contactItem, styles.contactItemPink)}>
                <div className={styles.contactItemIcon}></div>
                <div>
                  <h3>Our Home</h3>
                  <address>
                    Sorted Group Third Floor<br />
                    The Union, Albert Square<br />
                    Manchester, M2 6LW
                  </address>
                  <Button theme="whiteoutline"><a rel="noopener noreferrer" target="_blank" href="https://www.google.com/maps/place/Union/@53.4795847,-2.247726,17z/data=!3m1!4b1!4m5!3m4!1s0x487bb1c189b360c9:0xd691fb290ec8ae84!8m2!3d53.4795847!4d-2.2455373">Get directions</a></Button>
                </div>
              </div>
            </div>
          </div>

          <div className={cx("container", styles.contactForm)}>
            <DualSplit>
              <div>
                <div className={styles.contactCopy}>
                  <h2 className="text-pink">Our contact form</h2>
                  <p>Leave us a message with your details and we’ll be in touch as soon as possible. Whether it’s about the football, the weather, or delivering delight, we won’t judge.</p>
                </div>
              </div>
              <div className="hubspot">
                <HubspotForm
                  portalId='4432335'
                  formId='1246f546-b895-4f2a-ab0d-3c736d842b2e'
                  onSubmit={() => console.log('Submit!')}
                  onReady={() => console.log('Form ready!')}
                  loading={<div>Loading...</div>}
                  css={hubspotStyles}
                  cssClass="hubspotContact"
                />
              </div>
            </DualSplit>
          </div>
        </div>

      </Layout>
    )
  }
}

export default Contact
